@charset "utf-8";

///////////////////////////////////////////////////////////
// heading
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.lv2Heading {
  font-size: m.clamp_size(26, 32);
  text-align: center;
  color: v.$clr_dark_red;
  margin-bottom: m.clamp_size(30, 50);

  .en {
    display: block;
    font-size: 0.4375em;
    font-family: v.$fnt_en;
    text-transform: uppercase;
  }
  .ja {
    display: block;
    font-size: inherit;
    font-weight: 600;
    line-height: 1;
    margin-top: 0.2em;
  }
}