@charset "utf-8";

///////////////////////////////////////////////////////////
// 404
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.error {
  text-align: center;
  margin-top: m.clamp_size(60, 80);
}
.error__list {
  margin-top: m.clamp_size(20, 30);
}
.error__item {
  & + & {
    margin-top: m.clamp_size(14, 16);
  }
}
.error__solution {
  margin: m.clamp_size(40, 50) 0 5rem;
}
.error__solution_title {
  font-size: m.clamp_size(18, 20);
  font-weight: 600;
  color: v.$clr_dark_red;
}
.error__solution_text {
  margin-top: 1.25em;
}