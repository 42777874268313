@charset "utf-8";

///////////////////////////////////////////////////////////
// section
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.section {
  margin-top: m.clamp_size(60, 100);
}