@use "../setting/variables" as v;

.u-color {
  &-black {
    color: v.$clr_black;
  }

  &-gray {
    color: v.$clr_gray;
  }

  &-blue {
    color: v.$clr_blue;
  }

  &-white {
    color: #fff;
  }
}

.u-text {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}

.u-font {
  &-small {
    font-size: 1.2rem;
    color: v.$clr_gray;
  }

  &-size {
    @for $i from 10 through 30 {
      &-#{$i} {
        font-size: calc(#{$i} * 0.1rem);
      }
    }
  }
}