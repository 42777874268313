@charset "utf-8";

///////////////////////////////////////////////////////////
// newsList
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.newsList {
  border-top: 1px solid #e8e8e8;
}
.newsList__item {
  position: relative;
  border-bottom: 1px solid #e8e8e8;

  &::after {
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    content: "\f105";
    font-size: 1.8rem;
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    color: v.$clr_dark_red;
  }
}
.newsList__item_link {
  position: relative;
  display: flex;
  align-items: center;
  padding: m.clamp_size(20, 30) m.clamp_size(42, 50) m.clamp_size(20, 30) 1.8%;
  @include m.mq-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: v.$clr_dark_red;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 1;
  }

  @include m.hover {
      &::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }

    .newsList__item_title {
      color: v.$clr_dark_red;
    }
  }
}
.newsList__item_date  {
  time {
    font-size: m.clamp_size(12, 14);
    font-weight: 600;
    color: v.$clr_dark_red;
  }
}
.newsList__item_title {
  font-size: m.clamp_size(14, 16);
  margin-left: m.clamp_size(20, 50);
  transition: color .4s ease;
  &::after {
    content: "\f1c1";
    display: inline-block;
    font-size: m.clamp_size(14, 16);
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    color: v.$clr_dark_red;
    margin-left: 0.9375em;
  }
  @include m.mq-down(md) {
    width: 100%;
    margin-top: m.clamp_size(5, 10, 375, 768);
    margin-left: 0;
  }
}
.newsList__noPost {
  text-align: center;
  padding: 2rem 0;
}