
@charset "utf-8";

///////////////////////////////////////////////////////////
// btn
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.btn {
  position: relative;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  width: m.clamp_size(260, 320);
  max-width: 100%;
  padding: .5em 1em;
  box-sizing: border-box;
  border-radius: 25px;
  border: 2px solid transparent;
  background-color: v.$clr_dark_red;
  transition: all .3s ease;
  @include m.linkColor(#fff);
  @include m.hover {
    color: v.$clr_dark_red;
    border: 2px solid v.$clr_dark_red;
    background-color: transparent;
  }

  &--arrow-r {
    &::after {
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      content: "\f105";
      font-size: 1.8rem;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
    }
    @include m.hover {
      &::after {
        color: v.$clr_dark_red;
      }
    }
  }

  &--arrow-l {
    &::after {
      position: absolute;
      top: 50%;
      left: 1em;
      transform: translateY(-50%);
      content: "\f104";
      font-size: 1.8rem;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
    }
    @include m.hover {
      &::after {
        color: v.$clr_dark_red;
      }
    }
  }
}