
@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   pageTopBtn
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.pageTopBtn {
  position: fixed;
  right: 3.9%;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  z-index: 15;
  opacity: 0;
  transition: opacity .35s ease;
  @include m.mq-down(md) {
    right: 2rem;
  }
}
.pageTopBtn_link {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid v.$clr_gray;
  background-color: v.$clr_gray;
  transition: all .3s ease;
  @include m.linkColor(v.$clr_dark_red);

  &::before {
    position: absolute;
    top: .2em;
    left: 50%;
    transform: translateX(-50%);
    content: "\f106";
    font-family: "Font Awesome 6 Free";
    font-size: 2.8rem;
    line-height: 1;
    color: v.$clr_dark_red;
    transition: color .3s ease;
  }

  @include m.hover {
    color: #fff;
    border: 1px solid v.$clr_lite_gray;
    background-color: v.$clr_dark_red;

    &::before {
      color: #fff;
    }

    .pageTopBtn_text {
      color: #fff;
    }
  }
}
.pageTopBtn_text {
  display: block;
  color: v.$clr_dark_red;
  font-size: 1.4rem;
  font-family: v.$fnt_en;
  text-align: center;
  margin-top: 1.2em;
  transition: color .3s ease;
}