@charset "utf-8";

///////////////////////////////////////////////////////////
// main
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.main {
  margin-top: 10rem;
  padding: m.clamp_size(50, 100) 0 m.clamp_size(70, 100);
  min-height: calc(100vh - 40rem);
  @include m.mq-down(md) {
    margin-top: 8rem;
  }
}