@charset "utf-8";

///////////////////////////////////////////////////////////
// container
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.container {
  width: min(92%, 111rem);
  margin: 0 auto;
}