@charset "utf-8";

///////////////////////////////////////////////////////////
// header
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.header {
  position: absolute;
  top: -10rem;
  width: 100%;
  height: 10rem;
  // margin-top: 10rem;
  color: #fff;
  background-color: v.$clr_dark_red;
  transition: top .4s;
  will-change: top;
  z-index: 999;
  @include m.mq-down(md) {
    height: 8rem;
    top: -8rem;
  }

  &.is-active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 7rem;
    margin-top: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    animation: headerDownAnime 0.6s forwards;
    @include m.mq-down(md) {
      height: 6.5rem;
    }

    .header__inner {
      height: 7rem;
      @include m.mq-down(md) {
        height: 6.5rem;
      }
    }

    .header__outlook {
      display: none;
    }
  }
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10rem;
  @include m.mq-down(md) {
    height: 8rem;
  }
}
.header__title {
  display: flex;
  align-items: center;
}
.header__logo {
  display: block;
  img {
    width: m.clamp_size(147, 167, 320, 1280);
    @include m.mq-down(md) {
      width: m.clamp_size(110, 154, 320, 768);
    }
  }
}
.header__siteName {
  font-size: m.clamp_size(14, 16, 768, 1280);
  font-weight: 500;
  line-height: 1.56;
  margin-left: 1.25em;
  @include m.mq-down(md) {
    font-size: m.clamp_size(10, 14, 320, 768);
  }
}
.header__nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include m.mq-down(md) {
    position: fixed;
    top: 0;
    right: 0;
    width: 40vw;
    max-width: 50rem;
    height: 100vh;
    height: 100dvh;
    justify-content: flex-start;
    background-color: v.$clr_dark_red;
    transform: translateX(100%);
    transition: transform .5s ease;
    pointer-events: none;
    overflow: scroll;
    z-index: 150;
    width: 60vw;
  } 
  @include m.mq-down(sm) {
    width: 100vw;
    max-width: 100%;
  }

  &.is-open {
    transform: translateX(0);
    pointer-events: auto;
  }
}
.header__navList {
  display: flex;
  margin-top: .3rem;
  @include m.mq-down(md) {
    flex-direction: column;
    align-items: flex-start;
    padding: 12rem 21%;
    margin-top: 0;
  }
  @include m.mq-down(sm) {
    padding: 12rem m.clamp_size(44, 120, 320, 576);
  }
}
.header__navItem {
  @include m.mq-down(md) {
    opacity: 0 ;
    visibility: hidden;
  }

  & + & {
    margin-left: m.clamp_size(24, 50, 768, 1280);
    @include m.mq-down(md) {
      margin-left: 0;
      margin-top: 3rem;
    }
  }
}
.header__navLink {
  position: relative;
  display: inline-block;
  font-size: m.clamp_size(14, 16, 768, 1280);
  padding-bottom: 0.25em;
  --linkLine: 0;
  @include m.linkColor(#fff);
  @include m.mq-down(md) {
    font-size: 1.8rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transform: scale(var(--linkLine));
  }

  &--outlook {
    padding-right: 1.2em;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-66%);
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      background: url("img/icon_link_wh.svg") no-repeat;
      background-size: cover;
    }
  }

  .en {
    display: block;
    font-size: m.clamp_size(11, 12, 768, 1280);
    font-family: v.$fnt_en;
    text-align: center;
    @include m.mq-down(md) {
      font-size: 1.2rem;
      text-align: left;
    }
  }
  .ja {
    display: block;
    font-size: m.clamp_size(14, 16, 768, 1280);
    font-weight: 500;
    @include m.mq-down(md) {
      font-size: 1.8rem;
    }
  }
}
.header__outlook {
  text-align: right;
}
.header__outlook_link {
  position: relative;
  display: inline-block;
  font-size: m.clamp_size(11, 12);
  padding-right:  m.clamp_size(11, 12);
  @include m.linkColor(#fff);
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    width: 1rem;
    height: 1rem;
    background: url("img/icon_link_wh.svg") no-repeat;
    background-size: cover;
  }
}

/* SP用 メニュー開閉ボタン
---------------------------------------------------------- */
.header__navBtn {
  display: none;
  @include m.mq-down(md) {
    display: grid;
    place-items: center;
    width: 5rem;
    height: 5rem;
    background: #fff;
    z-index: 200;
  }
  @include m.mq-down(sm) {
    margin-left: auto;
  }

  &.is-open {
    .header__navBtn_line {
      &:nth-child(1) {
        transform: translate(-50%, 7px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
        transform: translate(-100%, -50%);
      }
      &:nth-child(3) {
        transform: translate(-50%, -7px) rotate(-45deg);
      }
    }
  }
}
.header__navBtn_inner {
  position: relative;
  width: 2.7rem;
  height: 1.6rem;
  display: inline-block;
}
.header__navBtn_line {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  background: v.$clr_dark_red;
  border-radius: 2px;
  transition: all .4s ease;

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .2s, transform .4s ease;
  }
  &:nth-child(3) {
    bottom: 0;
  }
}

/* SP用 メニュー開閉時の背景
---------------------------------------------------------- */
.header__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  transform: translateX(100%);
  background-color: rgba(255,255,255, 0.8);
  z-index: 100;
  transition: transform .5s ease;
  display: none;
  @include m.mq-down(xl) {    
    display: block;
    &.is-open {
      transform: translateX(0);
    }
  }
  @include m.mq-down(sm) {
    display: none;
  }
}

/* メニュー展開時 背景固定
---------------------------------------------------------- */
body.is-open {
  height: 100%;
  overflow: hidden;
}