@use "sass:math";

///////////////////////////////////////////////////////////
// display
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// 必要ファイルインポート
// ---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

// ---------------------------------------------------------
// メディアクエリ以上をdisplay:none
// ---------------------------------------------------------
.d-up-xxl-none {
  @include m.mq-up(xxl) {
    display: none;
  }
}
.d-up-xl-none {
  @include m.mq-up(xl) {
    display: none;
  }
}
.d-up-lg-none {
  @include m.mq-down(lg) {
    display: none;
  }
}
.d-up-md-none {
  @include m.mq-up(md) {
    display: none;
  }
}
.d-up-sm-none {
  @include m.mq-up(sm) {
    display: none;
  }
}

// ---------------------------------------------------------
// メディアクエリ以下をdisplay:none
// ---------------------------------------------------------
.d-down-xxl-none {
  @include m.mq-down(xxl) {
    display: none;
  }
}
.d-down-xl-none {
  @include m.mq-down(xl) {
    display: none;
  }
}
.d-down-lg-none {
  @include m.mq-down(lg) {
    display: none;
  }
}
.d-down-md-none {
  @include m.mq-down(md) {
    display: none;
  }
}
.d-down-sm-none {
  @include m.mq-down(sm) {
    display: none;
  }
}