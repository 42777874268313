@use "sass:map";

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.u-container {
  margin: auto;
  overflow: hidden;
  width: map.get( v.$container_width, 'fluid' );
  padding: 0 calc(v.$gap / 2);
  
  @include m.mq-up(sm) {
    width: map.get( v.$container_width, 'sm' );
  }
  @include m.mq-up(md) {
    width: map.get( v.$container_width, 'md' );
  }
  @include m.mq-up(lg) {
    width: map.get( v.$container_width, 'lg' );
  }
  @include m.mq-up(xl) {
    width: map.get( v.$container_width, 'xl' );
  }
  @include m.mq-up(xxl) {
    width: map.get( v.$container_width, 'xxl' );
  }

  &__fluid {
    width: map.get( v.$container_width, 'fluid' );
  }
}

.u-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(v.$gap / 2 * -1);
  margin-left: calc(v.$gap / 2 * -1);
}

.u-col {
  display: block;
  flex-grow: 1;
  flex-basis: 0;
  padding: calc(v.$gap / 2);
  @for $i from 1 through 12 {
    &--#{$i} {
      width: calc( 100% / 12 * $i );
      padding: 0 calc(v.$gap / 2);
      flex-grow: unset;
      flex-basis: unset;
    }
    @each $key, $value in v.$breakpoint_up {
      &--#{$key}-#{$i} {
        padding: 0 calc(v.$gap / 2);
        width: 100%;
        @media #{$value} {
          width: calc( 100% / 12 * $i ) !important;
          flex-grow: unset;
          flex-basis: unset;
        }
      }
    }
  }
}