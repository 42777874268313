@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   pager
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: m.clamp_size(50, 53);
  margin-left: -.5rem;

  a,
  span {
    padding: 0 !important;
  }

  .page,
  .current,
  .previouspostslink,
  .nextpostslink,
  .extend {
    margin: 0 .5rem;
  }
  
  .page,
  .current,
  .previouspostslink,
  .nextpostslink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: m.clamp_size(12, 14);
    font-weight: 600;
    width: m.clamp_size(28, 54, 320, 1280);
    height: m.clamp_size(28, 54, 320, 1280);
    color: v.$clr_dark_red;
  }

  .page,
  .previouspostslink,
  .nextpostslink {
    position: relative;
    border: 2px solid transparent;
    background-color: v.$clr_gray;
    transition: .3s cubic-bezier(.77,0,.18,1);

    @include m.hover {
      color: v.$clr_dark_red;
      border: 2px solid v.$clr_dark_red !important;
      background-color: #fff;
    }
  }

  span.current {
    color: #fff;
    background-color: v.$clr_dark_red;
    border: 2px solid v.$clr_dark_red !important;
    pointer-events: none;
    box-sizing: content-box;
  }

  .previouspostslink,
  .nextpostslink {
    &::after {
      content: "";
      font-size: 1.8rem;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
      color: v.$clr_dark_red;
      margin-right: .1rem;
      vertical-align: middle;
      // transition: border-color .3s cubic-bezier(.77,0,.18,1);
    }

    // @include m.hover {
    //   &::after {
    //     border-color: v.$clr_dark_red;
    //     transition-duration: .2s;
    //   }
    // }
  }

  .previouspostslink {
    &::after {
      content: "\f104";
    }
  }

  .nextpostslink {
    &::after {
      content: "\f105";
    }
  }

  .first,
  .last {
    display: none;
  }

  // .extend {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-weight: 600;
  //   width: m.clamp_size(15, 17);
  //   height: m.clamp_size(15, 17);
  //   border-radius: 50%;
  //   border: none;

  //   &:last-of-type {
  //     display: none;
  //   }
  // }
}