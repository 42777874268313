@charset "utf-8";

///////////////////////////////////////////////////////////
// footer
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;

.footer {
  color: #fff;
  background-color: v.$clr_dark_red;
}
.footer__inner {
  text-align: center;
  padding: m.clamp_size(40, 60) 0 m.clamp_size(30, 50);
}
.footer__logo_link {
  display: inline-block;
}
.footer__outlook {
  margin-top: m.clamp_size(20, 30);
}
.footer__outlook_link {
  position: relative;
  font-weight: 500;
  display: inline-block;
  padding-left: 1.6em;
  @include m.linkColor(#fff);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: m.clamp_size(14, 16);
    height: m.clamp_size(14, 16);
    background: url("img/icon_link_wh.svg") no-repeat;
    background-size: cover;
  }
}
.footer__address {
  margin-top: m.clamp_size(14, 16);
  .address {
    font-size: 1.3rem;
  }
  .tell {
    position: relative;
    display: inline-block;
    font-size: 1.3rem;
    padding-left: 1.6em;
    margin-top: .4em;
  
    &::before {
      content: "\f095";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-48%);
      font-size: 1.3rem;
      font-weight: 900;
      font-family: "Font Awesome 6 Free";
    }

    .num {
      font-size: 1.3rem;
      @include m.linkColor(#fff);
    }
  }
}
.footer__copyright {
  display: grid;
  place-items: center;
  height: 4rem;
  background-color: v.$clr_dark_gray;
}
.footer__copyright_text {
  font-size: 1rem;
  color: #fff;
}