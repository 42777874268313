@charset "utf-8";

///////////////////////////////////////////////////////////
// 変数定義用SASS
///////////////////////////////////////////////////////////

// ---------------------------------------------------------
// ブレイクポイント
// ---------------------------------------------------------
$breakpoint_up: (
  sm: "screen and (min-width: 576px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 992px)",
  xl: "screen and (min-width: 1200px)",
  xxl: "screen and (min-width: 1400px)"
);
$breakpoint_down: (
  sm: "screen and (max-width: 575.98px)",
  md: "screen and (max-width: 767.98px)",
  lg: "screen and (max-width: 991.98px)",
  xl: "screen and (max-width: 1199.98px)",
  xxl: "screen and (max-width: 1399.98px)"
);

// ---------------------------------------------------------
// レイアウト
// ---------------------------------------------------------
// コンテナ幅
$container_width: ( fluid:100%, md:720px, lg:960px, xl:1140px, xxl:1320px );

// 余白
$gap: 3rem; // 30px

// ---------------------------------------------------------
// hover
// ---------------------------------------------------------
$opacity: .6;
$transition_opacity: opacity .4s ease;

// ---------------------------------------------------------
// カラー
// ---------------------------------------------------------
$clr_black: #333;
$clr_gray: #dbdbdb;
$clr_lite_gray: #e3e3e3;
$clr_dark_gray: #2c2c2c;
$clr_dark_red: #7f1a3e;
$clr_blue: #1591fc;

// ---------------------------------------------------------
// デフォルトフォント設定
// ---------------------------------------------------------
// フォントサイズ
$fnt_size_default: 1.6rem;

// 太さ
$fnt_weight_default: 400;

// 行間
$fnt_lh_default: 1.6;

// フォントファミリー
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@600&display=swap');

$fnt_ja: "Hiragino Sans","Hiragino Kaku Gothic ProN","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","Meiryo UI","メイリオ",sans-serif;
$fnt_en: 'Archivo Narrow', sans-serif;